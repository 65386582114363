:root {
    --video-width: 100vw;
    --video-height: 100vh;
}

@media (min-aspect-ratio: 16/9) {
    :root {
        --video-height: 56.25vw;
    }
}

@media (max-aspect-ratio: 16/9) {
    :root {
        --video-width: 177.78vh;
    }
}

.video-background {
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
}

.video-background video {
    object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.VideoTag {
    height: 102vh;
    
}